.env {
  top: 0;
  background: rgba($color: #ffffff, $alpha: 0.8);
  position: fixed;
  padding: 16px;
  z-index: 10000;
}

.react-multi-carousel-track {
  height: 100%;
}

.react-multi-carousel-track li {
  margin-left: 1px;
}

// .react-multi-carousel-list-style {
//     position: "absolute";
//     width: "80%";
//     left: "50%";
//     top: "50%";
//     height: "100%";
//     object-fit: "contain";
//     transform: "translate(-50%, -50%)";
//     z-index: -1;
// }
